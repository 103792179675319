var __extensible_get__ = require("extensible-runtime").immutable;

export var TIME_FORMAT = 'HH:mm';
export var DATE_FORMAT = 'MM/DD/YYYY';
export var DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
export var userRoles = ['superadmin', 'admin', 'user', 'yardcontrol', 'driver', 'readonly', 'driversupervisor', 'nonworkingdriver'];
export var ROC_SITES = ['smithfieldtarheel', 'smithfieldnortheast'];
/**method to check whether trailer status type is curtainside or not */

export var checkNewlyAddedEquipmentType = function checkNewlyAddedEquipmentType(trailer) {
  var _ref;

  return (_ref = trailer.get('Type') === 8 || trailer.get('Type') === 9 || trailer.get('Type') === 10) !== null && _ref !== void 0 ? _ref : false;
};
export var checkSideDumpAndFlatbedEquipmentType = function checkSideDumpAndFlatbedEquipmentType(trailer) {
  var _ref2;

  return (_ref2 = trailer.get('Type') === 8 || trailer.get('Type') === 9) !== null && _ref2 !== void 0 ? _ref2 : false;
};
export var RFID_HARD_TAGTYPE_ID = 3;
export var MAP_GEOJSON_HTTP_REQUEST_FREQUENCY = 20000; //ms

export var TrailerState = {
  'None': 0,
  'Arrive': 1,
  'Depart': 2
};
/** trailer status types */

export var TrailerStatusType = {
  'None': 0,
  'Loaded': 1,
  'Empty': 2,
  "OutOfService": 3,
  "Partial": 4,
  "PreCooling": 5,
  "RedTagLoaded": 6,
  "RedTagEmpty": 7,
  "StorageEmpty": 8,
  "StorageLoaded": 9,
  "StoragePartial": 10
};
/** trailer direction types */

export var TrailerDirectionType = {
  'None': 0,
  'Inbound': 1,
  'Outbound': 2
};
/** location types */

export var LocationType = {
  "None": 0,
  "Yard": 1,
  "Door": 2,
  "Scale": 3,
  "ParkingSpot": 4,
  "Center": 5,
  "Gate": 6,
  "ManyToOne": 7
};
/** Task Actions */

export var TaskActionsType = {
  "None": 0,
  "Pull": 1,
  "Spot": 2,
  "PreCool": 3,
  "PullSpot": 4,
  "Bump": 5,
  "Fuel": 6,
  "Shuttle": 7,
  "ManualMove": 8,
  "Yard": 9,
  "Wash": 10,
  "DockActivityTask": 11,
  "ForcePull": 12,
  "ForceSpot": 13,
  "ForcePullSpot": 14,
  "ForceYard": 15
};
/** Trailer Types */

export var TrailerTypes = {
  "None": 0,
  "Reefer": 1,
  "Van": 2,
  "ContainerReefer": 3,
  "ContainerDry": 4,
  "Livestock": 5,
  "OpenTop": 6,
  "Tanker": 7,
  "SideDump": 8,
  "Flatbed": 9,
  "Curtainside": 10,
  "Hopper": 11
};
/**Task Status Types*/

export var TaskStatusTypes = {
  "Pending": 0,
  "Working": 1,
  "Paused": 2,
  "Completed": 3
};
/**Task Sub Status Types */

export var TaskSubStatusTypes = {
  "None": 0,
  "Priority": 1,
  "RedLight": 2,
  "GladHandle": 3,
  "TrailerMove": 4,
  "Other": 5,
  "ReSequenced": 6,
  "TrailerInDoor": 7
};