var __extensible_get__ = require("extensible-runtime").immutable;

import Immutable from 'immutable';
import { DATE_FORMAT } from '../config/constants';
import { isMoment } from 'moment';
import moment from 'moment';
/**default values of trailer form */

export var initPayload = function initPayload(task) {
  return Immutable.fromJS({
    ID: 0,
    ScacID: task ? task.ScacID : 0,
    ScacName: task ? task.ScacName : '',
    Number: task ? task.TrailerNumber : '',
    'Trailer#': '',
    // just a local value
    Status: 0,
    Type: 0,
    AKA: task ? task['AKA'] : '',
    ShipmentNumber: task ? task.ShipmentNumber : '',
    StatusType: task ? task.TrailerStatusType : 0,
    DirectionType: task ? task.DirectionType : 0,
    Intent: 0,
    TransactionID: task ? task.TransactionID : '',
    ReeferFuelLevel: 0,
    SetTemperature: task ? task.SetTemperature : '',
    BoxTemperature: task ? task.BoxTemperature : '',
    TargetTemperature: task ? task.TargetTemperature : '',
    TrailerWeight: '',
    OutboundWeight: '',
    SealNumber: '',
    SealIntact: false,
    SealMatchesBOL: false,
    OdorFree: false,
    TandemWheels: false,
    EyesOnArrive: false,
    EyesOnDepart: false,
    Continuous: false,
    Notes: '',
    EPCID: '',
    RFIDTagID: 0,
    PhoneNumber: '',
    FreightID: 0,
    FreightName: '',
    ReferenceID: '',
    ShipmentDate: "",
    Inspection: false,
    SealNumber2: '',
    SealIntact2: false,
    SealMatchesBOL2: false,
    SealNumber3: '',
    SealIntact3: false,
    SealMatchesBOL3: false
  });
};
/**default filters of trailer */

export var initFilters = function initFilters() {
  return Immutable.fromJS({
    states: [-1, 0, 1, 2, 3],
    scacs: [0],
    types: [0],
    statusTypes: [0],
    directionTypes: [0],
    shipments: [0],
    intents: [0, 1, 2],
    // intents: [0],
    auditType: 0,
    arrivalStartDate: '',
    arrivalEndDate: '',
    departureStartDate: '',
    departureEndDate: '',
    keyword: '',
    skip: 0,
    take: 10,
    sortKey: 'LastActivityDate',
    // default sorting key for default trailers tab
    sortType: 'desc',
    currentAuditOnly: false,
    UnauditedTrailers: false,
    rfidKeyword: '',
    locations: [0],
    freights: [0],
    ShipmentDate: '',
    inspections: [0],
    ProductProtectionPartialTrailer: false,
    ProductProtectionTrailerInDoor: false,
    IsReArrival: false,
    DepartedInError: false
  });
};
/**payload used when we update trailer state and call trailer state http */

export var trailerStatePayload = function trailerStatePayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Immutable.Map();
  return {
    TrailerID: obj.get('id'),
    State: obj.get('state'),
    EyesOn: obj.get('eyesOn'),
    BoxTemperature: obj.get('boxTemp'),
    SetTemperature: obj.get('setTemp'),
    ReeferFuelLevel: obj.get('fuelLevel'),
    SealIntact: obj.get('sealIntact'),
    SealMatchesBOL: obj.get('sealMatch'),
    SealNumber: obj.get('sealNumber') || '',
    TrailerWeight: obj.get('trailerWeight') !== '' && obj.get('trailerWeight') !== null ? parseInt(obj.get('trailerWeight')) : null,
    ShipmentNumber: obj.get('shipmentNumber'),
    OutboundWeight: obj.get('outboundWeight') !== '' ? parseInt(obj.get('outboundWeight')) : null,
    PhoneNumber: obj.get('PhoneNumber') || '',
    FreightName: obj.get('FreightName') || '',
    FreightID: obj.get('FreightID'),
    Inspection: obj.get('Inspection'),
    IsReArrival: obj.get('IsReArrival') || false,
    DepartedInError: obj.get('DepartedInError') || false,
    SealNumber2: obj.get('sealNumber2') || '',
    SealIntact2: obj.get('sealIntact2') || false,
    SealMatchesBOL2: obj.get('sealMatchesBOL2') || false,
    SealNumber3: obj.get('sealNumber3') || '',
    SealIntact3: obj.get('sealIntact3') || false,
    SealMatchesBOL3: obj.get('sealMatchesBOL3') || false
  };
};
/**method to transform data into simple object when we submit trailer */

export var trailerPayload = function trailerPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initPayload();
  return {
    Trailer: {
      ID: obj.get('ID'),
      ScacID: obj.get('ScacID'),
      Number: obj.get('Number'),
      Status: obj.get('Status'),
      AKA: obj.get('AKA') || '',
      Type: obj.get('Type'),
      ShipmentNumber: obj.get('ShipmentNumber'),
      StatusType: obj.get('StatusType'),
      DirectionType: obj.get('DirectionType'),
      Intent: obj.get('Intent'),
      TransactionID: obj.get('TransactionID') || '',
      LocationID: obj.get('LocationID') || 0,
      LocationName: obj.get('LocationName') || '',
      ReeferFuelLevel: parseInt(obj.get('ReeferFuelLevel')) || 0,
      SetTemperature: obj.get('SetTemperature') !== '' ? parseInt(obj.get('SetTemperature')) : null,
      BoxTemperature: obj.get('BoxTemperature') !== '' ? parseInt(obj.get('BoxTemperature')) : null,
      TargetTemperature: obj.get('TargetTemperature') !== '' ? parseInt(obj.get('TargetTemperature')) : obj.get('SetTemperature') !== '' && obj.get('BoxTemperature') !== '' && obj.get('ID') === 0 // a new trailer would have the Target Temp set from Set Temp if empty
      ? obj.get('SetTemperature') : null,
      TrailerWeight: obj.get('TrailerWeight') !== '' ? parseInt(obj.get('TrailerWeight')) : null,
      OutboundWeight: obj.get('OutboundWeight') !== '' ? parseInt(obj.get('OutboundWeight')) : null,
      SealNumber: obj.get('SealNumber'),
      SealIntact: obj.get('SealIntact'),
      SealMatchesBOL: obj.get('SealMatchesBOL'),
      OdorFree: obj.get('OdorFree'),
      Continuous: obj.get('Continuous'),
      TandemWheels: obj.get('TandemWheels'),
      EyesOnArrive: obj.get('EyesOnArrive'),
      EyesOnDepart: obj.get('EyesOnDepart'),
      Notes: obj.get('Notes') || '',
      EPCID: obj.get('EPCID'),
      RFIDTagID: obj.get('RFIDTagID'),
      PhoneNumber: obj.get('PhoneNumber') || '',
      UnauditedTrailers: obj.get('UnauditedTrailers'),
      State: obj.get('State'),
      FreightID: obj.get('FreightID'),
      FreightName: obj.get('FreightName') || '',
      ReferenceID: obj.get('ReferenceID') || '',
      ShipmentDate: obj.get('ShipmentDate') ? moment(obj.get('ShipmentDate')).format(DATE_FORMAT) : '',
      Inspection: obj.get('Inspection'),
      SealNumber2: obj.get('SealNumber2'),
      SealIntact2: obj.get('SealIntact2'),
      SealMatchesBOL2: obj.get('SealMatchesBOL2'),
      SealNumber3: obj.get('SealNumber3'),
      SealIntact3: obj.get('SealIntact3'),
      SealMatchesBOL3: obj.get('SealMatchesBOL3')
    }
  };
};
/** method to transform data into simple object when we fetch trailers list by applying filters or by default */

export var getTrailerList = function getTrailerList() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilters;
  return {
    types: obj.get('types').toJS(),
    states: obj.get('states').toJS(),
    intents: obj.get('intents').toJS(),
    statusTypes: obj.get('statusTypes').toJS(),
    keyword: obj.get('keyword'),
    // just a keyword
    directionTypes: obj.get('directionTypes'),
    scacs: obj.get('scacs').toJS(),
    locations: obj.get('locations').toJS(),
    shipments: obj.get('shipments').toJS(),
    auditType: +obj.get('auditType'),
    arrivalStartDate: isMoment(obj.get('arrivalStartDate')) ? obj.get('arrivalStartDate').format(DATE_FORMAT) : obj.get('arrivalStartDate'),
    arrivalEndDate: isMoment(obj.get('arrivalEndDate')) ? obj.get('arrivalEndDate').format(DATE_FORMAT) : obj.get('arrivalEndDate'),
    departureStartDate: isMoment(obj.get('departureStartDate')) ? obj.get('departureStartDate').format(DATE_FORMAT) : obj.get('departureStartDate'),
    departureEndDate: isMoment(obj.get('departureEndDate')) ? obj.get('departureEndDate').format(DATE_FORMAT) : obj.get('departureEndDate'),
    skip: obj.get('skip'),
    take: obj.get('take'),
    sortKey: obj.get('sortKey'),
    sortType: obj.get('sortType'),
    currentAuditOnly: obj.get('currentAuditOnly'),
    rfidKeyword: obj.get('rfidKeyword'),
    UnauditedTrailers: obj.get('UnauditedTrailers'),
    freights: obj.get('freights'),
    ShipmentDate: isMoment(obj.get('ShipmentDate')) ? obj.get('ShipmentDate').format(DATE_FORMAT) : obj.get('ShipmentDate'),
    inspections: obj.get('inspections').toJS(),
    ProductProtectionPartialTrailer: obj.get('ProductProtectionPartialTrailer'),
    ProductProtectionTrailerInDoor: obj.get('ProductProtectionTrailerInDoor')
  };
};
export var exportPayload = function exportPayload() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Immutable.Map();
  return {
    States: obj.get('states').filter(function (x) {
      return x;
    }),
    Types: obj.get('types').filter(function (x) {
      return x;
    }),
    StatusTypes: obj.get('statusTypes').filter(function (x) {
      return x;
    }),
    Scacs: [obj.get('scacs')].filter(function (x) {
      return x;
    }),
    ShipmentNumbers: obj.get('shipments').filter(function (x) {
      return x;
    }).map(function (sn) {
      return +sn;
    }),
    DirectionTypes: obj.get('directionTypes').filter(function (x) {
      return x;
    }),
    ArrivedStartDate: isMoment(obj.get('arrivalStartDate')) ? obj.get('arrivalStartDate').format(DATE_FORMAT) : obj.get('arrivalStartDate'),
    ArrivedEndDate: isMoment(obj.get('arrivalEndDate')) ? obj.get('arrivalEndDate').format(DATE_FORMAT) : obj.get('arrivalEndDate'),
    DepartedStartDate: isMoment(obj.get('departureStartDate')) ? obj.get('departureStartDate').format(DATE_FORMAT) : obj.get('departureStartDate'),
    DepartedEndDate: isMoment(obj.get('departureEndDate')) ? obj.get('departureEndDate').format(DATE_FORMAT) : obj.get('departureEndDate'),
    ShipmentDate: isMoment(obj.get('ShipmentDate')) ? obj.get('ShipmentDate').format(DATE_FORMAT) : obj.get('ShipmentDate'),
    inspectionList: GetInspectionData(obj.get('inspections').toJS())
  };
};
export var GetInspectionData = function GetInspectionData(inspections) {
  var inspectionValues = inspections.filter(function (val) {
    return val !== 0 ? val : '';
  });

  if (inspectionValues.length > 0) {
    var index = inspectionValues.indexOf(2);
    if (index !== -1) inspectionValues[index] = 0;
    return inspectionValues.toString();
  } else {
    return "";
  }
};
export var trailerHistoryPayload = function trailerHistoryPayload(TrailerID, BeginDate, EndDate) {
  return {
    TrailerID: TrailerID,
    BeginDate: isMoment(BeginDate) ? BeginDate.format(DATE_FORMAT) : '',
    EndDate: isMoment(EndDate) ? EndDate.format(DATE_FORMAT) : ''
  };
};
export var trailerPhotectorPayload = function trailerPhotectorPayload(TrailerNumber, LastActivityDate, ScacName, Name) {
  return {
    TrailerNumber: TrailerNumber,
    StartDate: moment(LastActivityDate).subtract(3, 'day').format(),
    EndDate: moment(LastActivityDate).add(1, 'day').format(),
    ScacName: ScacName,
    Name: Name
  };
};
export var trailerOldestIdlePayload = function trailerOldestIdlePayload(scacId, trailerType) {
  return {
    ScacId: scacId,
    TrailerType: trailerType
  };
};
export var trailerNumberSearch = function trailerNumberSearch() {
  return Immutable.fromJS({
    states: [0, 2],
    intents: [0, 1, 2],
    keyword: '',
    skip: 0,
    take: 20,
    sortKey: 'LastActivityDate',
    // default sorting key for default trailers tab
    sortType: 'desc'
  });
};
export var getTrailerInfo = function getTrailerInfo() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : trailerNumberSearch;
  return {
    states: obj.get('states').toJS(),
    intents: obj.get('intents').toJS(),
    keyword: obj.get('keyword'),
    // just a keyword
    skip: obj.get('skip'),
    take: obj.get('take'),
    sortKey: obj.get('sortKey'),
    sortType: obj.get('sortType')
  };
};